<template>
  <div class="pb-40">
    <Navbar mode="solid" class="navbar" :show-btn-icon="true" :btn-link-path="backPath" :show-currency-picker="false" />
    <main class="main">
      <section class="section" v-if="loading">
        <div class="main--container">
          <SkeletonTripRequest class="mb-8" />
          <SkeletonTripRequest class="mb-8" />
          <SkeletonTripRequest class="mb-8" />
          <SkeletonTripRequest class="mb-8" />
          <SkeletonTripRequest class="mb-8" />
          <SkeletonTripRequest />
        </div>
      </section>
      <section class="request-details section" v-if="!loading">
        <div class="main--container">
          <h1 class="page-title">
            {{ $t("page.trips.tour_details.title") }}
          </h1>
          <div class="tour--container">
            <figure class="tour--img">
              <img
                :src="tourPhoto" />
            </figure>
            <div>
              <h2 class="tour--title">
                {{ tour.tour_info.title }} · {{ destination }}
              </h2>
              <div class="tour-card-rating">
                <Rating size="xxs" :is-static="true" :rating="reviews.rating" />
                <span class="tour--reviews">{{ $tc("reviews.n_reviews", reviews.count) }}</span>
              </div>
              <span class="tour-card-agency">

                {{ $t("common.offered_by", {agency: partnerFirstName}) }}
              </span>
            </div>
          </div>
          <h2 class="price--title" v-t="'page.group_tour.payment.section.details.title'" />
          <table class="table">
            <tr class="table--row">
              <td v-t="'page.group_tour.payment.section.details.tour_date'" />
              <td>
                {{ startDate | date("date") }} –
                {{ endDate | date("date") }}
              </td>
            </tr>
            <tr class="table--row">
              <td v-t="'page.group_tour.payment.section.details.number_of_travellers'" />
              <td>{{ tour.shared_rooms + tour.single_rooms }}</td>
            </tr>
          </table>
          <Button size="xs" :text="$t('common.details')" kind="secondary" class="btn-details" :show-icon="true"
            type="button" icon-class="bx bxs-info-circle" @click.native="openTravellerDetails()"></Button>
          <Divider class="my-8" />

          <h2 class="price--title" v-t="'page.group_tour.payment.section.price_details.title'" />
          <table class="table">
            <tr class="table--row--subtotal table--row" v-if="singleRooms > 0">
              <td>
                {{ tour.tour_info.single_room_price | currency(tour.currency) }}
                &#215; {{ $tc("page.group_tour.payment.section.price_details.single_rooms",singleRooms)
                                }}
              </td>
              <td>
                {{ subTotalSingleRooms | currency(tour.currency) }}
              </td>
            </tr>
            <tr class="table--row--subtotal table--row" v-if="sharedRooms > 0">
              <td>
                {{ tour.tour_info.shared_room_price | currency(tour.currency) }}
                &#215; {{$tc("page.group_tour.payment.section.price_details.shared_rooms", tour.shared_rooms)}}
              </td>
              <td>
                {{(subTotalSharedRooms) | currency(tour.currency)}}
              </td>
            </tr>
            <tr class="table--row--total table--row">
              <td>Total</td>
              <td>
                {{ totalPrice | currency(tour.currency) }}
              </td>
            </tr>
          </table>
        </div>
      </section>
    </main>
    <div class="traveller--details" v-if="!loading" v-show="showTravellerForm">
      <Button icon-class="bx bx-x" shape="round" :show-text="false" type="button" class="btn-close"
        @click.native="closeTravellerDetails()" />
      <h2 class="sub--title" v-t="'page.group_tour.payment.dialog.title'" />
      <div class="form-input--container">
        <table class="table" v-for="(traveller, index) in travellers" :key="index">
          <tr class="table--row">
            <td></td>
          </tr>
          <tr class="table--row">
            <td class="font-semibold">
              {{ traveller.firstName }} {{ traveller.lastName }}
              <span class="traveller--item--traveller--type--tag ml-2" v-if="traveller.lead"
                v-t="'common.lead_traveller'" />
            </td>
          </tr>
          <tr>
            <td class="text-black-lighter text-sm font-medium flex-wrap inline-flex gap-2">

              <span class="traveller--item--detail--tag">
                <i class="bx bxs-cake bx-xs"></i>
                {{ traveller.birthDate | date("date") }}
              </span>

              <span class="traveller--item--detail--tag">
                <i class="bx bx-world bx-xs"></i>
                {{ getNationalityByLang(traveller.nationality) }}
              </span>

              <span class="traveller--item--detail--tag" v-if="traveller.lead">
                <i class="bx bxs-phone bx-xs"></i>
                {{ traveller.phoneNumber }}
              </span>

              <span class="traveller--item--detail--tag" v-if="traveller.lead">
                <i class="bx bxs-envelope bx-xs"></i>
                {{ traveller.email }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <BackgroundLayer v-if="showBackground" @click.native="closeTravellerDetails()" />
  </div>
</template>
<script>
import api from "@/api";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import Button from "@/components/buttons/Button.vue";
import Divider from "@/components/dividers/Divider.vue";
import Rating from "@/components/review/Rating.vue";
import SkeletonTripRequest from "@/components/skeletons/SkeletonTripRequest.vue";
import countries from "@/data/world/countries.json";
export default {
  components: {
    BackgroundLayer,
    Button,
    Divider,
    Rating,
    SkeletonTripRequest,
  },
  data() {
    return {
      countries,
      countriesOptions: [],
      lang: localStorage.getItem("lang") || "en",
      showBackground: false,
      showTravellerForm: false,
      startDate: undefined,
      endDate: undefined,
      sharedRooms: undefined,
      singleRooms: undefined,
      tourId: undefined,
      tour: undefined,
      loading: true,
      agency: undefined,
      travellers: undefined,
      reviews: undefined,
      fallBackImg: require("@/assets/img/placeholders/placeholder.png"),
    };
  },
  computed: {
    backPath() {
      let tab = "";
      if (this.$route.query["previous_tab"] !== undefined) {
        tab = "?tab=" + this.$route.query["previous_tab"];
      }
      return `/account/trips${tab}`
    },
    subTotalSingleRooms() {
      return this.tour.tour_info.single_room_price * this.singleRooms;
    },
    subTotalSharedRooms() {
      return this.tour.tour_info.shared_room_price * this.tour.shared_rooms;
    },
    totalPrice() {
      return this.subTotalSharedRooms + this.subTotalSingleRooms;
    },
    partnerFirstName() {
      return this.$options.filters.agencyGenitive(this.agency.first_name);
    },
    destination() {
      if (this.lang === "en") return this.tour.tour_info.destination;
      if (this.lang === "fr") return this.tour.tour_info.destination_fr;

      return this.tour.tour_info.destination;
    },
    tourPhoto() {
            if (this.tour.tour_info.image) {
                return process.env.VUE_APP_FILE_BASE_PATH + this.tour.tour_info.image;
            }

            return this.fallBackImg;
    },
  },
  methods: {
    openTravellerDetails() {
      this.showBackground = this.showTravellerForm = true;
    },
    closeTravellerDetails() {
      this.showBackground = this.showTravellerForm = false;
    },
    getNationalityByLang(nationality) {
      let nationalityByLang = this.countriesOptions.filter(
        (option) => option.value === nationality
      );
      return nationalityByLang[0].name;
    },
  },
  created() {
    this.countries.forEach((country) => {
      this.countriesOptions.push({
        value: country["en"],
        name: country[this.lang],
        selected: false,
        disabled: false,
      });
    });
  },
  async mounted() {
    //Get tour
    this.tourId = this.$route.query["id"];
    let res = await api.getTourBooking(this.tourId);
    this.tour = await res.data.tour_booking;
    
    //Get tour dates
    let date = this.tour.tour_date.split("|");
    this.startDate = date[0];
    this.endDate = date[1];

    //Get tour rooms
    this.singleRooms = this.tour.single_rooms;
    this.sharedRooms = this.tour.shared_rooms;

    //Get tour agency
    let agencyId = this.tour.tour_info.agency;
    let agencyRes = await api.getTripAgent(agencyId);
    let agency = await agencyRes.data.partner;
    this.agency = agency;

    //Get agency reviews
    let originalTour = await api.getTour(this.tour.tour_id);
    this.reviews  = await originalTour.data.tour.partner.reviews;

    //Set travellers
    let otherTravellers = JSON.parse(this.tour.other_travelers);
    this.travellers = [
      {
        email: this.tour.email,
        firstName: this.tour.firstname,
        lastName: this.tour.lastname,
        lead: true,
        birthDate: this.tour.dob,
        nationality: this.tour.nationality,
        phoneNumber: this.tour.phone,
      },
      ...otherTravellers,
    ];

    //Stop loading
    this.loading = false;
  },
};
</script>
<style scoped>
.section {
  @apply pt-24;
}
.navbar {
  @apply fixed top-0 z-20 w-full border-none;
}
.navbar {
  @apply border-none;
}

.main{
  @apply px-4;
}

.page-title {
  @apply font-bold text-3xl text-black-base mb-12;
}

.btn-details {
  @apply ml-auto flex;
}
.tour--img {
  @apply rounded-lg overflow-hidden min-w-20 w-20 h-20;
  @apply relative;
}

.tour--img img {
  @apply absolute w-full h-full object-cover;
}
.tour-card-rating {
  @apply flex items-center gap-1;
}
.tour--container {
  @apply flex gap-4 mb-8;
}
.tour--title {
  @apply font-semibold text-black-base;
}

.tour-card-agency {
  @apply text-xs font-medium text-black-lighter;
  @apply flex items-center gap-1.5 mt-0.5;
}

.tour--reviews {
  @apply text-xs flex items-center;
}

.main--container {
  @apply max-w-xl w-full;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.page-title {
  @apply font-bold text-3xl text-black-base;
}

.price--title {
  @apply font-semibold text-black-base text-lg;
  @apply flex items-center gap-1;
}

.tour-card-rating {
  @apply flex gap-1.5 items-center;
}

.table {
  @apply w-full mt-3;
}

.table--row {
  @apply flex justify-between;
  @apply w-full pb-3 text-black-base;
}

.table--row--total {
  @apply font-semibold border-t border-grey-base pt-2.5;
}

.availability--dialog,
.traveller--details {
  @apply fixed left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2;
  @apply z-50 h-full md:h-auto overflow-hidden md:rounded-xl;
  @apply bg-white md:max-w-screen-sm w-full;
  @apply border border-grey-base py-12 px-4 sm:px-16;
  @apply overflow-y-auto;
}

.btn-close {
  @apply flex ml-auto;
}
.sub--title {
  @apply font-semibold text-2xl text-black-base;
}

.traveller--item--traveller--type--tag {
  @apply bg-violet-light bg-opacity-75 rounded-full text-violet-dark font-semibold;
  @apply text-xxs uppercase px-2.5 py-0.5 inline-block;
}

.traveller--item--detail--tag {
  @apply rounded-md px-2 py-1 bg-grey-base bg-opacity-50;
  @apply inline-flex items-center gap-1;
  @apply text-sm font-medium text-black-light;
}
</style>